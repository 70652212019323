import React, { useState } from 'react';
import classes from './AttachmentDownloadModal.module.css';
import { FiDownload, FiX } from 'react-icons/fi';
import { Box, Modal } from '@mui/material';
import Card from '../../../../CommonComponents/Card/Card';
import shortenFileName from '../../../../../HelperFunctions/shortenFileName';
import { useNavigate } from 'react-router-dom';
import { flutterEvents } from '../../../../../Constants/flutterEvents.js';
import { useFlutterWebView } from '../../../../../Context/FlutterWebView/FlutterWebViewContext.js';
import { sendEventToFlutter } from '../../../../../HelperFunctions/flutterMobile.js';

const AttachmentDownloadModal = ({
  attachmentModalData,
  setOpenAttachmentDownloadModal,
}) => {
  const [openModal, setOpenModal] = useState(true);
  // boolean value to detect flutter webview
  const isFlutterWebView = useFlutterWebView();

  const navigate = useNavigate();
  // function to close the modal
  const CloseAttachmentDownloadModal = () => {
    setOpenModal(false);
    setOpenAttachmentDownloadModal(false);
  };
  const attachmentDownloadHandler = async (downloadURL, fileName) => {
    if (isFlutterWebView) {
      sendEventToFlutter(flutterEvents.FILE_DOWNLOAD(downloadURL, fileName));
    } else {
      fetch(downloadURL)
        .then((response) => response.blob())
        .then((blob) => {
          const blobURL = window.URL.createObjectURL(new Blob([blob]));
          const a = document.createElement('a');
          a.href = blobURL;
          a.setAttribute('download', fileName);
          document.body.appendChild(a);
          a.click();
          setTimeout(() => {
            document.body.removeChild(a);
          }, 1000);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <>
      <Modal open={openModal} onClose={CloseAttachmentDownloadModal}>
        <Card>
          <div>
            <Box className={classes.popup}>
              <div>
                <div className={classes.header}>
                  <FiX
                    className="cross-button"
                    onClick={CloseAttachmentDownloadModal}
                  />
                  <h4 className="component-heading">ATTACHMENTS</h4>
                </div>
              </div>

              <div className={classes.listContainer}>
                {attachmentModalData.map((item) => (
                  <div className={classes.item} key={item.id}>
                    <div>
                      <h4 className="element-heading">
                        {shortenFileName(item.name, 20)}
                      </h4>
                      <p className="element-description">{item.size}</p>
                    </div>
                    <FiDownload
                      className={classes.icon}
                      onClick={() =>
                        attachmentDownloadHandler(item.file, item.name)
                      }
                    />
                  </div>
                ))}
              </div>
            </Box>
          </div>
        </Card>
      </Modal>
    </>
  );
};

export default AttachmentDownloadModal;
