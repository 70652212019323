import React, { createContext, useContext, useEffect, useState } from 'react';

const FlutterWebViewContext = createContext(false);

export const useFlutterWebView = () => useContext(FlutterWebViewContext);

export const FlutterWebViewProvider = ({ children }) => {
  const [isFlutterWebView, setIsFlutterWebView] = useState(false);

  useEffect(() => {
    if (navigator.userAgent.includes('Srijcon Mobile App')) {
      setIsFlutterWebView(true);
    }
  }, []);

  return (
    <FlutterWebViewContext.Provider value={isFlutterWebView}>
      {children}
    </FlutterWebViewContext.Provider>
  );
};
