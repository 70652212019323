import React, { useEffect, useState } from 'react';

// Stylesheet import
import classes from './SignIn.module.css';
import entryClasses from '../Entry.module.css';

// React form stuff imported
import { useForm } from 'react-hook-form';

// MUI stuff imported
import { IconButton, InputAdornment, TextField } from '@mui/material';

// Icons imported from React icons
import { FiLock, FiUnlock } from 'react-icons/fi';

// imports Modal component for Forgot password
import Modal from '../../Entry/ForgotPassword/ForgotPassword';

// import Account Not Registered ModalComponent
import AccountNotRegisteredModal from './AccountNotRegisteredModal/AccountNotRegisteredModal';

// import Email Verification Modal Component
import EmailVerificationModal from '../SignUp/EmailVerificationModal/EmailVerificationModal';

// Styled component imported
import styled from 'styled-components';

// Redux dispatch to dispatch user credentials
import { useDispatch } from 'react-redux';

// API base URL imported
import API from '../../../Axios/axios';

// Store related data
import {
  newNotifications,
  userProfileDataGet,
} from '../../../Store/userProfileSlice';

// Imported for navigation
import { useNavigate } from 'react-router-dom';
import { TVPagePostsGet } from '../../../Store/TVPagePostsSlice';
import { Helmet } from 'react-helmet-async';

import { apiErrorCodes } from '../../../apiErrorCodes';
import createWebSocket from '../../../WebSocket';
import { liveNotificationAdd } from '../../../Store/notificationSlice';
import { Oval } from 'react-loader-spinner';
import GoogleButton from '../../CommonComponents/GoogleButton/GoogleButton.js';
import { useFlutterWebView } from '../../../Context/FlutterWebView/FlutterWebViewContext';
import MobileAppFacebookButton from '../../CommonComponents/MobileAppFacebookButton/MobileAppFacebookButton';
import { createFakeGoogleWrapper } from '../../../HelperFunctions/googleAuth.js';
import { sendEventToFlutter } from '../../../HelperFunctions/flutterMobile.js';
import { flutterEvents } from '../../../Constants/flutterEvents.js';

const StyledTextField = styled(TextField)`
  label.focused {
    color: var(--color-gray-medium-3);
  }

  .MuiOutlinedInput-root {
    fieldset {
      border-color: var(--color-gray-medium-3);
    }

    &:hover fieldset {
      border-color: var(--color-gray-medium-3);
    }

    &.Mui-focused fieldset {
      border-color: var(--color-gray-dark-2);
    }
  }

  @media only screen and (max-width: 900px) {
    .MuiOutlinedInput-root {
      fieldset {
        border-color: var(--color-gray-dark-2);
      }

      &:hover fieldset {
        border-color: var(--color-gray-dark-2);
      }

      &.Mui-focused fieldset {
        border-color: var(--color-gray-dark-1);
      }
    }
  }
`;

export default function SignIn() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = localStorage.getItem('token');
  const [accountNotRegisteredModal, setAccountNotRegisteredModal] =
    useState(false);
  const [emailVerificationModal, setEmailVerificationModal] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const [checkedError, setCheckedError] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [checked, setChecked] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [socialLoginFailModal, setSocialLoginFailModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  // boolean value to detect flutter webview
  const isFlutterWebView = useFlutterWebView();

  // custom google auth button
  const [googleButtonWrapper, setGoogleButtonWrapper] = useState(null);

  const FB = window.FB;
  const google = window.google;

  // Stuff related to react hook form
  const {
    getValues,
    setValue,
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({ mode: 'onChange' });

  const closeEmailVerificationModal = () => {
    setButtonDisabled(false);
    setEmailVerificationModal(false);
  };

  const onSubmit = async (data) => {
    setUserEmail(data.email);
    setButtonDisabled(true);
    const formData = new FormData();
    formData.append('username', data.email);
    formData.append('password', data.password);
    setIsLoading(true);
    try {
      const response = await API.post('/accounts/login/', formData);
      if (response.data.code === apiErrorCodes.SUCCESS) {
        localStorage.setItem('token', response.data.token);
        setButtonDisabled(false);
        navigate('/');
        dispatch(userProfileDataGet()).then((response) => {
          const socket = createWebSocket(response.payload.id);

          socket.onmessage = (event) => {
            const message = JSON.parse(event.data);
            if (message?.notification?.code === 'new__notification') {
              dispatch(newNotifications(true));
              dispatch(liveNotificationAdd(message.notification.details));
            }
          };

          return () => {
            socket.close();
          };
        });
        dispatch(TVPagePostsGet());
      }
      if (response.data.profile === false) {
        navigate('/entry/userdetails');
      } else if (response.data.creative_interests === false) {
        navigate('/entry/creativeinterest');
      } else if (response.data.code === apiErrorCodes.VERIFY_ACCOUNT) {
        setEmailVerificationModal(true);
      }
    } catch (error) {
      setButtonDisabled(false);
      if (error.response.data.code === apiErrorCodes.EMAIL_NOT_FOUND) {
        setAccountNotRegisteredModal(true);
      } else if (
        error.response.data.code === apiErrorCodes.INVALID_PASSWORD &&
        error.response.data.slt
      ) {
        setError('password', {
          type: 'manual',
          message: 'The sign-in method is invalid.',
        });
        setButtonDisabled(false);
      } else if (
        error.response.data.code === apiErrorCodes.INVALID_PASSWORD ||
        error.response.data.code ===
          apiErrorCodes.EMAIL_AND_PASSWORD_ARE_REQUIRED ||
        error.response.data.code === apiErrorCodes.PASSWORD_IS_REQUIRED ||
        error.response.data.code === apiErrorCodes.USERNAME_IS_REQUIRED ||
        error.response.data.code ===
          apiErrorCodes.USERNAME_AND_PASSWORD_ARE_REQUIRED ||
        error.response.data.code === apiErrorCodes.USERNAME_NOT_FOUND ||
        error.response.data.code === apiErrorCodes.FAILED_TO_SEND_EMAIL
      ) {
        setError('password', {
          type: 'manual',
          message: 'Invalid credentials.',
        });
        setButtonDisabled(false);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const socialLoginRequest = async (formData) => {
    try {
      const response = await API.post('/accounts/social/login/', formData);
      if (response.data.code === apiErrorCodes.SUCCESS) {
        localStorage.setItem('token', response.data.token);
        setButtonDisabled(false);
        navigate('/');
        dispatch(userProfileDataGet()).then((response) => {
          const socket = createWebSocket(response.payload.id);

          socket.onmessage = (event) => {
            const message = JSON.parse(event.data);
            if (message?.notification?.code === 'new__notification') {
              dispatch(newNotifications(true));
              dispatch(liveNotificationAdd(message.notification.details));
            }
          };

          return () => {
            socket.close();
          };
        });
        dispatch(TVPagePostsGet());
      }
      if (response.data.profile === false) {
        navigate('/entry/userdetails');
      } else if (response.data.creative_interests === false) {
        navigate('/entry/creativeinterest');
      } else if (response.data.code === apiErrorCodes.VERIFY_ACCOUNT) {
        setEmailVerificationModal(true);
      }
    } catch (error) {
      setButtonDisabled(false);
      if (error.response.data.code === apiErrorCodes.EMAIL_NOT_FOUND)
        setAccountNotRegisteredModal(true);
      else if (error.response.data.slt)
        setError('password', {
          type: 'manual',
          message: `The sign-in method is invalid. Please sign-in using ${error.response.data.slt}`,
        });
      else
        setError('password', {
          type: 'manual',
          message: 'The sign-in method is invalid.',
        });
    } finally {
      setIsLoading(false);
    }
  };

  const handleGoogleResponse = async (response) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append('account_token', response.credential);
    formData.append('social_platform', 'google');
    socialLoginRequest(formData);
  };

  const startGoogleAuth = () => {
    if (isFlutterWebView) {
      sendEventToFlutter(flutterEvents.GOOGLE_SIGNIN);
    } else {
      googleButtonWrapper?.click();
    }
  };
  const handleFacebookResponse = (response, token) => {
    const formData = new FormData();
    formData.append('social_platform', 'facebook');
    formData.append('account_token', token);
    formData.append('firstName', response.first_name);
    formData.append('lastName', response.last_name);
    formData.append('email', response.email);
    socialLoginRequest(formData);
  };

  const getDataFromFacebook = (response) => {
    setIsLoading(true);
    FB.api('/me', { fields: ['first_name', 'last_name', 'email'] }, (_) =>
      handleFacebookResponse(_, response.authResponse.accessToken)
    );
  };

  window.getDataFromFacebook = getDataFromFacebook;

  useEffect(() => {
    if (token) {
      navigate('/');
    }
  }, []);

  useEffect(() => {
    if (!isFlutterWebView) {
      try {
        google?.accounts?.id.initialize({
          client_id:
            '967838303775-44proqouuadrgecgcpeiibfbmkah1oh1.apps.googleusercontent.com',
          callback: handleGoogleResponse,
        });

        setGoogleButtonWrapper(createFakeGoogleWrapper());

        // google?.accounts?.id.renderButton(
        //   document.getElementById('g_id_onload'),
        //   {
        //     theme: 'filled_blue',
        //     size: 'medium',
        //   }
        // );
      } catch (error) {
        console.error(error);
      }
    }
  }, []);

  useEffect(() => {
    if (!isFlutterWebView) {
      try {
        FB.init({
          appId: '6020095344761695',
          autoLogAppEvents: true,
          xfbml: true,
          version: 'v17.0',
        });
      } catch (error) {}
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Sign In | Srijcon</title>
        <link rel="canonical" href="/entry/signin" />
        <meta
          name="description"
          // content="Srijcon - Connect with innovators, inventors, makers, creators and share your projects, innovations, creative works or STEM creations with the world."
          content="Srijcon connects creative and Innovative minds to come together and share inspiring creations and ideas to impact the world."
        />
      </Helmet>
      {modalOpen && <Modal setOpenModal={setModalOpen} />}
      {emailVerificationModal && (
        <EmailVerificationModal
          userEmail={userEmail}
          closeEmailVerificationModal={closeEmailVerificationModal}
        />
      )}
      {accountNotRegisteredModal && (
        <AccountNotRegisteredModal
          setAccountNotRegisteredModal={setAccountNotRegisteredModal}
        />
      )}
      <div className={classes.screenContainer}>
        <div className={classes.mainDivContainer}>
          <div className={classes.divLeft}>
            <div className={classes.logoDiv}>
              <h1 className={classes.logoHeading}>Srijcon</h1>
            </div>
            <div className={classes.descriptionDiv}>
              <h1 className={classes.descriptionHeading}>
                Connect. Create. <br /> Impact.
              </h1>
              <p className={classes.descriptionText}>
                We connect Makers and Innovative minds to come together and
                share inspiring creations and ideas to impact the world.
              </p>
            </div>
          </div>
          <div className={classes.divRight}>
            {isLoading && (
              <Oval
                height={60}
                width={60}
                color="#d11414"
                wrapperStyle={{
                  padding: '0',
                  position: 'absolute',
                  display: 'flex',
                  width: '100%',
                  height: '100%',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backdropFilter: 'blur(8px)',
                  zIndex: '1',
                }}
                ariaLabel="oval-loading"
                secondaryColor="#d11414"
                strokeWidth={2}
                strokeWidthSecondary={2}
              />
            )}
            <div className={classes.rightContainerDesc}>
              <h2 className={classes.heading}>Welcome Back !</h2>
            </div>

            <div className={classes.socialLoginContainer}>
              <div className={classes.socialLoginGoogle}>
                <GoogleButton
                  text="Sign In with Google"
                  onClick={() => startGoogleAuth()}
                />
              </div>
              {/* <div className={classes.socialLoginFacebook}>
                {isFlutterWebView ? (
                  <MobileAppFacebookButton type="facebook_signin" />
                ) : (
                  <div
                    className="fb-login-button"
                    data-size="large"
                    data-onlogin="getDataFromFacebook"
                  >
                    Sign in with Facebook
                  </div>
                )}
              </div> */}
            </div>

            <div className={entryClasses.orText}>
              <p className={`element-description ${entryClasses.divider}`}>
                or
              </p>
            </div>

            <div className={classes.formContainer}>
              <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                <div className={classes.formFieldsContainer}>
                  <div className={classes.fieldsOnly}>
                    <StyledTextField
                      className={classes.textCheck}
                      label="Username / Email"
                      variant="outlined"
                      InputProps={{
                        style: { fontSize: 14, fontWeight: 400, zIndex: 0 },
                      }}
                      InputLabelProps={{
                        style: { fontSize: 14, color: 'gray', zIndex: 0 },
                      }}
                      {...register('email', {
                        required: 'Required field',
                        onChange: () => {
                          setValue(
                            'email',
                            getValues('email').replace(
                              /[^a-zA-Z0-9!#$%&'*+-/=?^_`{|@.]/gm,
                              ''
                            )
                          );
                        },
                      })}
                      error={!!errors?.email}
                      helperText={errors?.email ? errors.email.message : null}
                    />
                    <StyledTextField
                      className={classes.textCheck}
                      label="Password"
                      variant="outlined"
                      type={showPassword ? 'text' : 'password'} // <-- This is where the magic happens
                      InputLabelProps={{
                        style: { fontSize: 14, color: 'gray', zIndex: 0 },
                      }}
                      InputProps={{
                        style: { fontSize: 14, fontWeight: 400, zIndex: 0 }, // <-- This is where the toggle button is added.
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                            >
                              {showPassword ? <FiUnlock /> : <FiLock />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      {...register('password', {
                        required: 'Required field',
                        onChange: () => {
                          setValue(
                            'password',
                            getValues('password').replace(
                              /[^a-zA-Z0-9!#$%&'*+-/=?^_`{|@.]/gm,
                              ''
                            )
                          );
                        },
                      })}
                      helperText={
                        errors?.password ? errors.password.message : null
                      }
                    />
                  </div>

                  <div className={classes.moreFeatures}>
                    <span className={classes.checkbox}>
                      <input
                        type="checkbox"
                        name="checkbox"
                        id="checkbox"
                        style={{ backgroundColor: '#000' }}
                        onChange={() =>
                          checked ? setChecked(false) : setChecked(true)
                        }
                      />
                      <label htmlFor="checkbox">Remember me</label>
                    </span>
                    <p
                      onClick={() => {
                        setModalOpen(true);
                      }}
                      className={classes.forgotPasswordText}
                      style={{
                        cursor: 'pointer',
                      }}
                    >
                      Forgot Password?
                    </p>
                  </div>
                  {checkedError ? checkedError.checkbox : ''}
                  {!buttonDisabled ? (
                    <button type="submit" className={classes.button}>
                      Sign In
                    </button>
                  ) : (
                    <button type="button" className={classes.buttonDisabled}>
                      Sign In
                    </button>
                  )}
                </div>
              </form>
            </div>
            <div className={classes.signUpAsk}>
              <p>New to Srijcon?</p>
              <p
                className={classes.signUpParagraph}
                onClick={() => navigate('/entry/signup')}
              >
                Sign Up
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
