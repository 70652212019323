import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import BottomNavbarState from './Context/Navbar/bottomNavbarState';
import AppContextProvider from './Context/AppContextProvider';
import { theme } from './Styles/theme';
import { Provider } from 'react-redux';
import store from './Store/store';
import { StyledEngineProvider, ThemeProvider } from '@mui/material';
import { HelmetProvider } from 'react-helmet-async';
import { FlutterWebViewProvider } from './Context/FlutterWebView/FlutterWebViewContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <AppContextProvider>
            <HelmetProvider>
              <FlutterWebViewProvider>
                <App />
              </FlutterWebViewProvider>
            </HelmetProvider>
          </AppContextProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

//test comment from kartik
//test new comment on kartik2 branch
