export const flutterEvents = Object.freeze({
  GOOGLE_SIGNUP: { name: 'google_signup' },
  GOOGLE_SIGNIN: { name: 'google_signin' },
  FILE_DOWNLOAD: (downloadUrl, fileName) =>
  (
    {
      name: 'download',
      data: { url: downloadUrl, fileName: fileName }
    }
  ),
  COPY_TEXT: (text) =>
  (
    {
      name: 'copy',
      data: text
    }
  )
});
