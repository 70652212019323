const createWebSocket = (id) => {
  const socket = new WebSocket(
    `${process.env.REACT_APP_WEB_SOCKET_PATH}/ws/notification/${id}/`
  );

  // socket.onopen = () => {
  //   console.log('WebSocket connection established.');
  // };
  //
  // socket.onclose = () => {
  //   console.log('WebSocket connection closed.');
  // };

  return socket;
};

export default createWebSocket;
