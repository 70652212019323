import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, CardActionArea, Grid } from '@mui/material';

import termsImg from '../../Assets/Settings/termsImg.svg';
import helpdeskImg from '../../Assets/Settings/helpdeskImg.svg';
import editProfileImg from '../../Assets/Settings/editProfileImg.svg';
import editPasswordImg from '../../Assets/Settings/editPasswordImg.svg';
import creativeInterestImg from '../../Assets/Settings/creativeInterestImg.svg';
import deleteAccountImg from '../../Assets/Settings/deleteAccountImg.svg';

import FirstModal from './modals/AccountDeletion/AreYouSureModal/FirstModal';
import Helpdesk from './modals/Helpdesk/Helpdesk';
import EditPassword from './modals/EditPassword/EditPassword';
import TermsAndConditionsPage from './TermsAndConditionsPage/TermsAndConditionsPage';
import classes from './Settings.module.css';
import OptionModal from './modals/OptionModal/OptionModal';
import { Helmet } from 'react-helmet-async';
import API from '../../Axios/axios';

export default function Settings() {
  const navigate = useNavigate();

  const [helpdeskModal, setHelpdeskModal] = useState(false);
  const [optionModal, setOptionModal] = useState(false);
  const [editPasswordModal, setEditPasswordModal] = useState(false);
  const [deleteAccountModal, setDeleteAccountModal] = useState(false);
  const [termsAndConditionsPage, setTermsAndConditionsPage] = useState(false);

  const handleModal = () => {
    setOptionModal(false);
    setHelpdeskModal(false);
    setEditPasswordModal(false);
    setDeleteAccountModal(false);
  };
  const openInNewTab = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const checkProfileStatus = async () => {
    try {
      const response = await API.get('/accounts/profile/status', {
        headers: { Authorization: `token ${localStorage.getItem('token')}` },
      });
      if (response.data.message.profile === false)
        navigate('/entry/userdetails');
      else if (response.data.message.creative_interests === false)
        navigate('/entry/creativeinterest');
    } catch {
      throw new Error('Error in validating profile');
    }
  };

  useEffect(() => {
    checkProfileStatus();
  }, []);
  return (
    <>
      <Helmet>
        <title>Settings | Srijcon</title>
        <link rel="canonical" href="/settings" />
        <meta
          name="description"
          // content="Srijcon - Connect with innovators, inventors, makers, creators and share your projects, innovations, creative works or STEM creations with the world."
          content="Srijcon connects creative and Innovative minds to come together and share inspiring creations and ideas to impact the world."
        />
      </Helmet>
      <Box>{helpdeskModal && <Helpdesk handleModal={handleModal} />}</Box>
      <Box>{optionModal && <OptionModal handleModal={handleModal} />}</Box>
      <Box>
        {editPasswordModal && <EditPassword handleModal={handleModal} />}
      </Box>
      <Box>
        {deleteAccountModal && <FirstModal handleModal={handleModal} />}
      </Box>
      <Box>{termsAndConditionsPage && <TermsAndConditionsPage />}</Box>
      <div className={classes.settingsMainContainer}>
        <Grid className={classes.gridMainContainer} container>
          <Grid item xs={6} sm={4} className={classes.gridContainer}>
            {/* First */}
            <div className={classes.settingsCard}>
              <div onClick={() => setEditPasswordModal(true)}>
                <CardActionArea>
                  <div className={classes.imgDiv}>
                    <img
                      src={editPasswordImg}
                      alt=""
                      className={classes.editPasswordImg}
                      draggable="false"
                    />
                  </div>
                  <div className={classes.headingDiv}>
                    <h1 className={classes.descriptionText}>Edit Password</h1>
                  </div>
                </CardActionArea>
              </div>
            </div>
          </Grid>
          <Grid item xs={6} sm={4} className={classes.gridContainer}>
            {/* Second */}

            <div className={classes.settingsCard}>
              <div onClick={() => setOptionModal(true)}>
                <CardActionArea>
                  <div className={classes.imgDiv}>
                    <img
                      src={helpdeskImg}
                      alt=""
                      className={classes.helpdeskImg}
                      draggable="false"
                    />
                  </div>
                  <div className={classes.headingDiv}>
                    <h1 className={classes.descriptionText}>
                      Helpdesk/Feedback
                    </h1>
                  </div>
                </CardActionArea>
              </div>
            </div>
          </Grid>
          <Grid item xs={6} sm={4} className={classes.gridContainer}>
            {/* Third */}
            <div className={classes.settingsCard}>
              <a
                href="/termsandconditions"
                target="_blank"
                // onClick={() => {
                //   openInNewTab('/termsandconditions');
                // }}
              >
                <CardActionArea>
                  <div className={classes.imgDiv}>
                    <img
                      src={termsImg}
                      alt=""
                      className={classes.termAndConditionsImg}
                      draggable="false"
                    />
                  </div>
                  <div className={classes.headingDiv}>
                    <h1 className={classes.descriptionText}>Terms Of Use</h1>
                  </div>
                </CardActionArea>
              </a>
            </div>
          </Grid>
          <Grid item xs={6} sm={4} className={classes.gridContainer}>
            {/* Fourth */}
            <div className={classes.settingsCard}>
              <div
                onClick={() => {
                  navigate('/editprofile');
                }}
              >
                <CardActionArea>
                  <div className={classes.imgDiv}>
                    <img
                      src={editProfileImg}
                      alt=""
                      className={classes.editProfileImg}
                      draggable="false"
                    />
                  </div>
                  <div className={classes.headingDiv}>
                    <h1 className={classes.descriptionText}>Edit Profile</h1>
                  </div>
                </CardActionArea>
              </div>
            </div>
          </Grid>
          <Grid item xs={6} sm={4} className={classes.gridContainer}>
            {/* fifth */}
            <div className={classes.settingsCard}>
              <div
                onClick={() => {
                  navigate('/creativeinterest');
                }}
              >
                <CardActionArea>
                  <div className={classes.imgDiv}>
                    <img
                      src={creativeInterestImg}
                      alt=""
                      className={classes.creativeInterestImg}
                      draggable="false"
                    />
                  </div>
                  <div className={classes.headingDiv}>
                    <h1 className={classes.descriptionText}>
                      Creative Interest
                    </h1>
                  </div>
                </CardActionArea>
              </div>
            </div>
          </Grid>
          <Grid item xs={6} sm={4} className={classes.gridContainer}>
            {/* Six */}

            <div className={classes.settingsCard}>
              <div onClick={() => setDeleteAccountModal(true)}>
                <CardActionArea>
                  <div className={classes.imgDiv}>
                    <img
                      src={deleteAccountImg}
                      alt=""
                      className={classes.deleteAccountImg}
                      draggable="false"
                    />
                  </div>
                  <div className={classes.headingDiv}>
                    <h1 className={classes.descriptionText}>Delete Account</h1>
                  </div>
                </CardActionArea>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
