/**
 * This creates a hidden google button that can be clicked automatically
 * @returns
 */
const createFakeGoogleWrapper = () => {
  const googleLoginWrapper = document.createElement('div');
  googleLoginWrapper.style.display = 'none';
  googleLoginWrapper.classList.add('custom-google-button');
  document.body.appendChild(googleLoginWrapper);

  // Use GSI javascript api to render the button inside our wrapper
  // You can ignore the properties because this button will not appear
  window.google.accounts.id.renderButton(googleLoginWrapper, {
    type: 'icon',
    width: '200',
  });

  const googleLoginWrapperButton =
    googleLoginWrapper.querySelector('div[role=button]');

  return {
    click: () => {
      googleLoginWrapperButton.click();
    },
  };
};

export { createFakeGoogleWrapper };
