import React, { useEffect, useState } from 'react';

// navigation added in React
import { useNavigate, useParams } from 'react-router-dom';

// Stylesheets imported
import classes from './UserProfile.module.css';
import './UserProfile.css';

// Images
import SocialImpact from '../../Assets/Images/ReactionImages/SocialImpact.svg';
import EcoImpact from '../../Assets/Images/ReactionImages/EcoImpactPadding.svg';
import LogoImg from '../../Assets/Logo/logo.svg';

// Components
import { HiPencil as EditProfileIcon } from 'react-icons/hi';
import { FiBookmark, FiGrid, FiShare2 as ShareProfileIcon, FiUserCheck, FiUserPlus } from 'react-icons/fi';

// Tooltip Component imported
import MyToolTip from '../CommonComponents/MyToolTip';

// Fans Component imported
import Fans from './FansAndFavourites/Fans';

// Favourites' Component imported
import Favourites from './FansAndFavourites/Favourites';

// TVCardDescription2 Component imported
import TVCardDescription2 from '../TVPage/TVCard/TVCardDescription/TVCardDescription2';

// Material UI stuff imported
// API base addresses imported
import API from '../../Axios/axios';

// Redux dispatch to dispatch user credentials
import { useSelector } from 'react-redux';

// Import to calculate time difference
import numberRoundOff from '../../HelperFunctions/numberRoundOff';

// import to capitalize the first letter of every word in a string
import firstLetterCapitalizer from '../../HelperFunctions/firstLetterCapitalizer';
import { Helmet } from 'react-helmet-async';
import CopyLinkModal from "../TVPage/TVCard/TVCardInteraction/CopyLinkModal/CopyLinkModal";
import { Thumbnail } from './Thumbnail';
// flutter web view context to detect when web is running on flutter webview
import { useFlutterWebView } from '../../Context/FlutterWebView/FlutterWebViewContext';
import { sendEventToFlutter } from '../../HelperFunctions/flutterMobile.js';
import { flutterEvents } from '../../Constants/flutterEvents.js';

function UserProfile() {
  // boolean value to detect flutter webview
  const isFlutterWebView = useFlutterWebView();
  const params = useParams();
  const { name } = params;
  const navigate = useNavigate();
  const { userProfileData } = useSelector((state) => state.userProfileSlice);
  const [status, setStatus] = useState('loading');
  const [isCopyLinkModal, setIsCopyLinkModal] = useState(false);

  const [showFans, setShowFans] = useState(false);
  const [showFavourites, setShowFavourites] = useState(false);
  const [show, setShow] = useState(true);
  const [active, setActive] = useState(true);
  const [showTVCard, setShowTVCard] = useState(false);
  const [postData, setPostData] = useState();
  const allowedVideoExtensions = ['.mkv', '.mp4', '.mov'];


  const shareButtonClickHandler = async () => {
    setIsCopyLinkModal(true);
    // This will copy the link to the clipboard
    const link = `${window.location.origin}/accounts/${userProfileData.id}/profile/`;
    try {
      if (isFlutterWebView) {
        sendEventToFlutter(flutterEvents.COPY_TEXT(link));
      } else {
        await navigator.clipboard.writeText(link);
      }
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  };
  const fansClickHandler = () => {
    setShowFans(!showFans);
  };

  const favouritesClickHandler = () => {
    setShowFavourites(!showFavourites);
  };

  useEffect(() => {
    if (name) {
      (async function () {
        try {
          const response = await API.get(`/accounts/${name}/profile/`);
          setStatus('done');
        } catch (e) {
        }
      })();
    } else {
      setStatus('done');
    }
  }, []);

  const checkProfileStatus = async () => {
    try {
      const response = await API.get('/accounts/profile/status', {
        headers: { Authorization: `token ${localStorage.getItem('token')}` },
      });
      if (response.data.message.profile === false)
        navigate('/entry/userdetails');
      else if (response.data.message.creative_interests === false)
        navigate('/entry/creativeinterest');
    }
    catch {
      throw new Error('Error in validating profile');
    }
  };

  useEffect(() => {
    checkProfileStatus();
  }, []);

  const myContentClickHandler = async (id) => {
    navigate(`/post/${id}/`);
  };
  if (status === 'loading') {
    return '';
  }
  return (<>
    {showFans && <Fans handleSubmit={fansClickHandler} />}
    {showFavourites && <Favourites handleSubmit={favouritesClickHandler} />}
    {isCopyLinkModal && (<CopyLinkModal setIsCopyLinkModal={setIsCopyLinkModal} text={`Profile link copied.`} />)}
    <Helmet>
      <title>
        {userProfileData.first_name} {userProfileData.last_name} | Srijcon
      </title>
      <link
        rel="canonical"
        href={`/accounts/${userProfileData.id}/profile/`}
      />
      <meta
        name="description"
        content={`${userProfileData.impact_question}`}
        data-rh="true"
      />
    </Helmet>
    <div className={classes.main}>
      <div className={classes.UserProfile}>
        {/* For Banner At the top of the Above section */}
        <div className={classes.userBanner}>
          <img
            draggable="false"
            src={`${userProfileData.banner_pic}`}
            alt="BannerImg"
          />
        </div>


        <div
          className={classes.editBannerPencilDiv}
          style={{ visibility: name ? 'hidden' : '' }}
        >

          {/* Share Profile */}
          <MyToolTip title="Share Profile">
            <div className={classes.shareIconContainer} onClick={shareButtonClickHandler}>
              <ShareProfileIcon className={classes.shareIcon} />
            </div>
          </MyToolTip>

          {/* Pencil - Edit Button */}
          <MyToolTip title="Edit Profile">
            <div
              className={classes.editBannerPencil}
              onClick={() => {
                navigate('/editprofile');
              }}
            >
              <EditProfileIcon className={classes.featherUser2} />
            </div>
          </MyToolTip>
        </div>

        {/* Button to become a fan */}
        <div
          className={classes.fansUserPlusIcon}
          style={{ visibility: 'hidden' }}
        >
          <FiUserPlus className={classes.featherUser} />
        </div>

        {/* Button if you're already Favourites that person/account */}
        <div
          className={classes.favouritesUserCheckIcon}
          style={{ visibility: 'hidden' }}
        >
          <FiUserCheck className={classes.featherUserCheck} />
        </div>

        {/* User Profile Picture Display */}
        <div className={classes.userAvtar}>
          <div className={classes.imageAvt1}>
            <img
              draggable="false"
              src={userProfileData.profile_pic}
              alt="AvatarImage"
            />
          </div>

          {/* Person's Username  */}
          <div className={classes.userName}>
            <div className={classes.userNameText}>
              <h2>
                {userProfileData.first_name} {userProfileData.last_name}
              </h2>
              <p>
                {userProfileData.top_interest ? firstLetterCapitalizer(userProfileData.top_interest) : userProfileData.top_interest}
              </p>
            </div>
          </div>
        </div>

        {/* Score Container for all the Scores */}
        <div className={classes.scoreContainer}>
          <div className={classes.srijconScoreContainer}>
            <div className={classes.scoreIconContainer}>
              <img
                draggable="false"
                className={classes.srijconScoreImg}
                src={LogoImg}
                alt="scoreImage"
              />
              <h2 className={classes.score}>
                {numberRoundOff(userProfileData.meta.total_srijcon_score)}
              </h2>
            </div>
            <p className={classes.scoreContainerText}>Srijcon Score</p>
          </div>
          <div className={classes.ecoImpactContainer}>
            <div className={classes.scoreIconContainer}>
              <img
                draggable="false"
                className={classes.ecoImpactImg}
                src={EcoImpact}
                alt="scoreImage"
              />
              <h2 className={classes.score}>
                {numberRoundOff(userProfileData.meta.total_eco_impact)}
              </h2>
            </div>
            <p className={classes.scoreContainerText}>Eco Impact</p>
          </div>
          <div className={classes.socialImpactContainer}>
            <div className={classes.scoreIconContainer}>
              <img
                draggable="false"
                className={classes.socialImpactImg}
                src={SocialImpact}
                alt="scoreImage"
              />
              <h2 className={classes.score}>
                {numberRoundOff(userProfileData.meta.total_social_impact)}
              </h2>
            </div>
            <p className={classes.scoreContainerText}>Social Impact</p>
          </div>
          <div className={classes.vlContainer}>
            <hr
              style={{
                height: '100%', border: '0.1rem solid #cbcdda', color: '#cbcdda', backgroundColor: '#cbcdda',
              }}
            />
          </div>
          {/* <div className={classes.fansContainer} > */}
          <div className={classes.fansContainer} onClick={fansClickHandler}>
            <h2 className={classes.score}>
              {numberRoundOff(userProfileData.users.fans_count)}
            </h2>
            <p className={classes.fansAndFavouritesContainerText}>Fans</p>
          </div>
          {/* <div className={classes.favouritesContainer}> */}
          <div
            className={classes.favouritesContainer}
            onClick={favouritesClickHandler}
          >
            <h2 className={classes.score}>
              {numberRoundOff(userProfileData.users.favourites_count)}
            </h2>
            <p className={classes.fansAndFavouritesContainerText}>
              Favourites
            </p>
          </div>
        </div>
        {/* Container to display Impact description */}
        {userProfileData.impact_question === 'null' || userProfileData.impact_question === null || userProfileData.impact_question === '' ? (
          <div style={{ height: '0.1rem' }}></div>) : (<div className={classes.content}>
            <TVCardDescription2 impact={userProfileData.impact_question} />
          </div>)}
      </div>
      {/*  This code is for user's profile*/}
      {userProfileData.my_posts.length > 0 || userProfileData.my_saved_posts.length > 0 ? (
        <div className={classes.post}>
          <div className={classes.postSection}>
            <div className={classes.postHeader}>
              {<div
                className={classes.myContentBar}
                onClick={() => {
                  setShow(true);
                  setActive(false);
                  if (active) {
                    document
                      .querySelector('.gridIconPost')
                      .setAttribute('class', 'gridIconPostActive');
                    document
                      .querySelector('.myContent')
                      .setAttribute('class', 'myContentActive');
                    document
                      .querySelector('.bookmarkIconPostActive')
                      .setAttribute('class', 'bookmarkIconPost');
                    document
                      .querySelector('.savedContentActive')
                      .setAttribute('class', 'savedContent');
                  }
                }}
              >
                <FiGrid className="gridIconPostActive" />
                <p className="myContentActive">My Content</p>
              </div>}
              {name || userProfileData.my_saved_posts.length < 0 ? ('') : (<div
                className="savedContentBar"
                onClick={() => {
                  setShow(false);
                  setActive(true);
                  if (show) {
                    document
                      .querySelector('.gridIconPostActive')
                      .setAttribute('class', 'gridIconPost');
                    document
                      .querySelector('.myContentActive')
                      .setAttribute('class', 'myContent');
                    document
                      .querySelector('.bookmarkIconPost')
                      .setAttribute('class', 'bookmarkIconPostActive');
                    document
                      .querySelector('.savedContent')
                      .setAttribute('class', 'savedContentActive');
                  }
                }}
              >
                <FiBookmark className="bookmarkIconPost" />
                <p className="savedContent">Saved Content</p>
              </div>)}
            </div>

            <div className={classes.postBody}>
              {show ?
                <div className={classes.images} style={{ minHeight: '16.2rem' }}>
                  {userProfileData.my_posts.map(option =>
                    <div className={classes.modalImages} onClick={() => myContentClickHandler(option.id)}>
                      <Thumbnail
                        key={option.id}
                        alt="MyPostIcon"
                        src={option.image_field.images[0]}
                        multiple={option.image_field.image_count > 1}
                      />
                    </div>
                  )}
                </div>
                :
                <div className={classes.images} style={{ minHeight: '17rem' }}>
                  {userProfileData.my_saved_posts.map(option =>
                    <div className={classes.modalImages} onClick={() => myContentClickHandler(option.post.id)}>
                      <Thumbnail
                        key={option.post.id}
                        src={option.post.image_field.images[0]}
                        alt="MyPostIcon"
                        multiple={option.post.image_field.image_count > 1}
                      />
                    </div>
                  )}
                </div>
              }
            </div>
          </div>
        </div>) : ('')}
      <div className={classes.emptyDiv}></div>
    </div>
  </>);
}

export default UserProfile;
