/**
 * This is a function used to communicate with the mobile app
 * @param {string} event
 */
function sendEventToFlutter(event) {
  if (window.flutter_inappwebview) {
    window.flutter_inappwebview.callHandler('flutter_inappwebview', event);
  }
}

export { sendEventToFlutter };
