import React, { useEffect, useState } from 'react';

// Stylesheet import
import classes from './SignUp.module.css';
import entryClasses from '../Entry.module.css';

// React form stuff imported
import { useForm } from 'react-hook-form';

// MUI stuff imported
import { IconButton, InputAdornment, TextField } from '@mui/material';

// Icons imported from React icons
import { FiLock, FiUnlock } from 'react-icons/fi';

//  Modal  when the confirmation email is sent to their respective email address
import EmailVerificationModal from './EmailVerificationModal/EmailVerificationModal';

//  Modal  if user is already existing
import UserAlreadyExistModal from './UserAlreadyExistModal/UserAlreadyExistModal';

// Imported for navigation and To read and modify the query string in the URL for the current location
import { useNavigate, useSearchParams } from 'react-router-dom';

// Styled component imported
import styled from 'styled-components';

// API base URL imported
import API from '../../../Axios/axios';

// React helmet for SEO purposes
import { Helmet } from 'react-helmet-async';

// Modal if google login fails
import { apiErrorCodes } from '../../../apiErrorCodes';
import { useDispatch } from 'react-redux';
import { userProfileDataGet } from '../../../Store/userProfileSlice';
import { TVPagePostsGet } from '../../../Store/TVPagePostsSlice';
import { Oval } from 'react-loader-spinner';
import GoogleButton from '../../CommonComponents/GoogleButton/GoogleButton.js';
import MobileAppFacebookButton from '../../CommonComponents/MobileAppFacebookButton/MobileAppFacebookButton';
import { useFlutterWebView } from '../../../Context/FlutterWebView/FlutterWebViewContext';
import { createFakeGoogleWrapper } from '../../../HelperFunctions/googleAuth.js';
import { sendEventToFlutter } from '../../../HelperFunctions/flutterMobile.js';
import { flutterEvents } from '../../../Constants/flutterEvents.js';

// Customize MUI Text field using styled component
const StyledTextField = styled(TextField)`
  label.focused {
    color: var(--color-gray-medium-3);
  }

  .MuiOutlinedInput-root {
    fieldset {
      border-color: var(--color-gray-medium-3);
    }

    &:hover fieldset {
      border-color: var(--color-gray-medium-3);
    }

    &.Mui-focused fieldset {
      border-color: var(--color-gray-dark-2);
    }
  }

  @media only screen and (max-width: 900px) {
    .MuiOutlinedInput-root {
      fieldset {
        border-color: var(--color-gray-dark-2);
      }

      &:hover fieldset {
        border-color: var(--color-gray-dark-2);
      }

      &.Mui-focused fieldset {
        border-color: var(--color-gray-dark-1);
      }
    }
  }
`;

export default function SignUp() {
  // To read and modify the query string in the URL for the current location
  const [searchParams] = useSearchParams();
  const FB = window.FB;
  const google = window.google;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // react-hook-form stuff
  const {
    getValues,
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'onChange' });

  // boolean value to detect flutter webview
  const isFlutterWebView = useFlutterWebView();
  // state to store email of user
  const [userEmail, setUserEmail] = useState('');

  // boolean values to open and close userAlreadyExistModal
  const [userAlreadyExistModal, setUserAlreadyExistModal] = useState(false);

  // boolean values to open and close emailVerificationModal
  const [emailVerificationModal, setEmailVerificationModal] = useState(false);

  // function to set the boolean value to show and hide the password
  const [showPassword, setShowPassword] = useState(false);

  // boolean value to disable and enable buttons
  const [buttonDisabled, setButtonDisabled] = useState(false);

  // boolean value to show error on invalid email
  const [invalidEmailError, setInvalidEmailError] = useState(false);

  //  boolean value to check if checkbox is ticked or not
  const [checked, setChecked] = useState(null);

  const [checkedError, setCheckedError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // To store the search query parameters
  const myQueryParam = searchParams.get('code');
  // google auth button
  const [googleButtonWrapper, setGoogleButtonWrapper] = useState(null);
  // function to set the boolean value to show and hide the new password
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const closeEmailVerificationModal = () => {
    setButtonDisabled(false);
    setEmailVerificationModal(false);
  };

  // form submission function
  const onSubmit = async (data) => {
    // this block will run when checkbox is ticked
    if (checked) {
      // this will disable the button until the response comes from API server
      setIsLoading(true);
      setUserEmail(data.email);
      setButtonDisabled(true);
      setInvalidEmailError(false);

      const formData = new FormData();
      formData.append('email', data.email);
      formData.append('password', data.password);
      // if URL has  a referral code then it will be sent to backend or an empty key (without value) will be sent
      if (myQueryParam) {
        formData.append('referral_code', myQueryParam);
      }

      try {
        const response = await API.post('/accounts/register/', formData);
        if (response.data.code === apiErrorCodes.SUCCESS) {
          // this will enable the button, set invalid error false and open the EmailVerificationModal
          setInvalidEmailError(false);
          setButtonDisabled(false);
          setEmailVerificationModal(true);
        }
      } catch (error) {
        if (error.response.data.code === apiErrorCodes.INVALID_EMAIL) {
          // this will enable the button and set invalid error true
          setInvalidEmailError(true);
          setButtonDisabled(false);
        } else if (
          error.response.data.code === apiErrorCodes.SERIALIZATION_FAILED
        ) {
          if (
            error.response.data.message.email.toString() ===
            'account with this email already exists.'
          ) {
            // this will enable the button, set invalid error false and open the UserAlreadyExistModal
            setInvalidEmailError(false);
            setButtonDisabled(false);
            setUserAlreadyExistModal(true);
          } else if (
            error.response.data.message.email.toString() ===
              'This field is required' ||
            error.response.data.message.email.toString() === 'Invalid Email' ||
            error.response.data.message.email.toString() === 'Disposable Email'
          ) {
            // this will enable the button and set invalid error true
            setInvalidEmailError(true);
            setButtonDisabled(false);
          }
        }
      } finally {
        setIsLoading(false);
      }
    } else {
      setChecked(false);
    }
  };

  const socialSignupRequest = async (formData) => {
    try {
      const response = await API.post('/accounts/social/register/', formData);
      if (response.data.code === apiErrorCodes.ACCOUNT_VERIFIED) {
        localStorage.setItem('token', response.data.token);
        if (response.data.profile === false) {
          navigate('/entry/userdetails');
        } else if (response.data.creative_interests === false) {
          dispatch(userProfileDataGet());
          navigate('/entry/creativeinterest');
        } else {
          navigate('/');
          dispatch(userProfileDataGet());
          dispatch(TVPagePostsGet());
        }
      }
    } catch (error) {
      if (error.response.data.code === apiErrorCodes.INVALID_EMAIL) {
        // this will enable the button and set invalid error true
        setInvalidEmailError(true);
        setButtonDisabled(false);
      } else if (
        error.response.data.code === apiErrorCodes.SERIALIZATION_FAILED
      ) {
        if (
          error.response.data.message.email.toString() ===
          'account with this email already exists.'
        ) {
          // this will enable the button, set invalid error false and open the UserAlreadyExistModal
          setInvalidEmailError(false);
          setButtonDisabled(false);
          setUserAlreadyExistModal(true);
        } else if (
          error.response.data.message.email.toString() ===
            'This field is required' ||
          error.response.data.message.email.toString() === 'Invalid Email' ||
          error.response.data.message.email.toString() === 'Disposable Email'
        ) {
          // this will enable the button and set invalid error true
          setInvalidEmailError(true);
          setButtonDisabled(false);
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleGoogleResponse = (response) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append('account_token', response.credential);
    formData.append('social_platform', 'google');
    socialSignupRequest(formData);
  };

  const startGoogleAuth = () => {
    if (isFlutterWebView) {
      sendEventToFlutter(flutterEvents.GOOGLE_SIGNUP);
    } else {
      googleButtonWrapper?.click();
    }
  };
  const handleFacebookResponse = (response, token) => {
    const formData = new FormData();
    formData.append('social_platform', 'facebook');
    formData.append('account_token', token);
    formData.append('firstName', response.first_name);
    formData.append('lastName', response.last_name);
    formData.append('email', response.email);
    socialSignupRequest(formData);
  };

  const getDataFromFacebook = (response) => {
    setIsLoading(true);
    FB.api('/me', { fields: ['first_name', 'last_name', 'email'] }, (_) =>
      handleFacebookResponse(_, response.authResponse.accessToken)
    );
  };
  window.getDataFromFacebook = getDataFromFacebook;

  // this will run when component is rendered
  useEffect(() => {
    // this will redirect to the TV Page if user is already logged in
    if (localStorage.getItem('token')) {
      navigate('/');
    }
    setUserAlreadyExistModal(false);
  }, []);

  // This function will be called when URL has referral code
  useEffect(() => {
    if (myQueryParam) {
      (async () => {
        try {
          const response = await API.post('/accounts/referral/', {
            referral_code: myQueryParam,
          });
        } catch (error) {}
      })();
    }
  }, []);

  useEffect(() => {
    if (!isFlutterWebView) {
      try {
        google.accounts.id.initialize({
          client_id:
            '967838303775-44proqouuadrgecgcpeiibfbmkah1oh1.apps.googleusercontent.com',
          callback: handleGoogleResponse,
        });
        setGoogleButtonWrapper(createFakeGoogleWrapper());

        // google.accounts.id.renderButton(
        //   document.getElementById('g_id_onload'),
        //   {
        //     theme: 'filled_blue',
        //     size: 'medium',
        //     text: 'signup_with',
        //   }
        // );
      } catch (error) {}
    }
  }, []);

  useEffect(() => {
    if (!isFlutterWebView) {
      try {
        FB.init({
          appId: '6020095344761695',
          autoLogAppEvents: true,
          xfbml: true,
          version: 'v17.0',
        });
      } catch (error) {}
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Sign Up | Srijcon</title>
        <link rel="canonical" href="/entry/signup" />
        <meta
          name="description"
          // content="Srijcon - Connect with innovators, inventors, makers, creators and share your projects, innovations, creative works or STEM creations with the world."
          content="Srijcon connects creative and Innovative minds to come together and share inspiring creations and ideas to impact the world."
        />
      </Helmet>
      {userAlreadyExistModal && (
        <UserAlreadyExistModal
          setUserAlreadyExistModal={setUserAlreadyExistModal}
        />
      )}
      {emailVerificationModal && (
        <EmailVerificationModal
          userEmail={userEmail}
          closeEmailVerificationModal={closeEmailVerificationModal}
        />
      )}
      <div className={classes.screenContainer}>
        <div className={classes.mainDivContainer}>
          <div className={classes.divLeft}>
            <div className={classes.logoDiv}>
              <h1 className={classes.logoHeading}>Srijcon</h1>
            </div>
            <div className={classes.descriptionDiv}>
              <h1 className={classes.descriptionHeading}>
                Connect. Create. <br /> Impact.
              </h1>
              <p className={classes.descriptionText}>
                We connect Makers and Innovative minds to come together and
                share inspiring creations and ideas to impact the world.
              </p>
            </div>
          </div>
          <div className={classes.divRight}>
            {isLoading && (
              <Oval
                height={60}
                width={60}
                color="#d11414"
                wrapperStyle={{
                  padding: '0',
                  position: 'absolute',
                  display: 'flex',
                  width: '100%',
                  height: '100%',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backdropFilter: 'blur(8px)',
                  zIndex: '1',
                }}
                ariaLabel="oval-loading"
                secondaryColor="#d11414"
                strokeWidth={2}
                strokeWidthSecondary={2}
              />
            )}
            <div className={classes.rightContainerDesc}>
              <h2 className={classes.heading}>Join Now</h2>
            </div>

            <div className={classes.socialLoginContainer}>
              <div className={classes.socialLoginGoogle}>
                <GoogleButton
                  text="Sign Up with Google"
                  onClick={() => startGoogleAuth()}
                />
              </div>
              {/* <div className={classes.socialLoginFacebook}>
                {isFlutterWebView ? (
                  <MobileAppFacebookButton type="facebook_signup" />
                ) : (
                  <div
                    className="fb-login-button"
                    data-size="large"
                    data-onlogin="getDataFromFacebook"
                    data-scope="email"
                  >
                    Sign up with Facebook
                  </div>
                )}
              </div> */}
              {/*<div>*/}
              {/*  <div className={classes.socialLogin}></div>*/}
              {/*</div>*/}
            </div>
            <div className={classes.orText}>
              <p className={`element-description ${entryClasses.divider}`}>
                or
              </p>
            </div>

            <div className={classes.formContainer}>
              <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                <div className={classes.formFieldsContainer}>
                  <div className={classes.fieldsOnly}>
                    <StyledTextField
                      className={classes.textCheck}
                      label="Email"
                      variant="outlined"
                      InputProps={{
                        style: {
                          fontSize: '1.4rem',
                          fontWeight: 400,
                          zIndex: 0,
                        },
                      }}
                      InputLabelProps={{
                        style: {
                          fontSize: '1.4rem',
                          color: '#6e6e6e',
                          zIndex: 0,
                        },
                      }}
                      {...register('email', {
                        required: 'Required field',
                        pattern: {
                          value:
                            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                          message: 'Invalid Email.',
                        },
                        onChange: () => {
                          setValue(
                            'email',
                            getValues('email').replace(
                              /[^a-zA-Z0-9!#$%&'*+-/=?^_`{|@.]/gm,
                              ''
                            )
                          );
                          setInvalidEmailError(false);
                        },
                      })}
                      error={!!errors?.email}
                      helperText={
                        errors?.email
                          ? errors.email.message
                          : invalidEmailError
                          ? 'Invalid Email'
                          : null
                      }
                    />
                    <StyledTextField
                      className={classes.textCheck}
                      label="Create password"
                      variant="outlined"
                      type={showPassword ? 'text' : 'password'} // <-- This is where the magic happens
                      InputLabelProps={{
                        style: {
                          fontSize: '1.4rem',
                          color: '#6e6e6e',
                          zIndex: 0,
                        },
                      }}
                      InputProps={{
                        style: {
                          fontSize: '1.4rem',
                          fontWeight: 400,
                          zIndex: 0,
                        },
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                            >
                              {showPassword ? <FiUnlock /> : <FiLock />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      {...register('password', {
                        required: 'Required field',
                        pattern: {
                          // Must have alphabets, Number and minimum 8 characters, but it can also contain special characters.
                          value:
                            /^(?=.*\d)(?=.*[a-zA-Z])[a-zA-Z0-9!#$%&'*+-/=?^_`{|@.]{8,}$/,

                          // Must have Special Character, UpperCase, LowerCase, Number and minimum 8 characters.
                          // /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                          message:
                            'Must be (a - z), (0 - 9) and minimum 8 characters.', // 'Must have Special Character, UpperCase, LowerCase, Number and minimum 8 characters.'
                        },
                        onChange: () => {
                          setValue(
                            'password',
                            getValues('password').replace(
                              /[^a-zA-Z0-9!#$%&'*+-/=?^_`{|@.]/gm,
                              ''
                            )
                          );
                        },
                      })}
                      error={!!errors?.password}
                      helperText={
                        errors?.password ? errors.password.message : null
                      }
                    />
                  </div>

                  <div className={classes.moreFeatures}>
                    <div className={classes.checkbox}>
                      <input
                        type="checkbox"
                        name="checkbox"
                        id="checkbox"
                        style={{ color: 'red', backgroundColor: 'red' }}
                        onChange={() => {
                          checked ? setChecked(false) : setChecked(true);
                        }}
                      />
                      <label
                        htmlFor="checkbox"
                        style={
                          checked === false
                            ? { color: 'red' }
                            : { color: '#6e6e6e' }
                        }
                      >
                        I agree to the{' '}
                        <a
                          target="_blank"
                          style={
                            checked === false
                              ? { color: 'red' }
                              : { color: '#b6193a' }
                          }
                          href="/termsandconditions"
                        >
                          "Terms of Use"
                        </a>
                      </label>
                    </div>
                  </div>
                  {checkedError ? checkedError.checkbox : ''}
                  {!buttonDisabled ? (
                    <button type="submit" className={classes.button}>
                      Sign Up
                    </button>
                  ) : (
                    <button type="button" className={classes.buttonDisabled}>
                      Sign Up
                    </button>
                  )}
                </div>
              </form>
            </div>
            <div className={classes.signUpAsk}>
              <p>Member at Srijcon?</p>
              <p
                className={classes.signUpParagraph}
                onClick={() => navigate('/entry/signin')}
              >
                Sign In
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
